<template>
  <div id="sendGround" :class="!isApp ? 'appTop' : ''">
    <div class="main">
      <!-- step1 -->
      <div>
        <div class="stepTitle">
          <img src="../../imgs/learn/step1.png" alt="" />请选择组合修炼的藏品
        </div>
        <div class="card-box">
          <div class="" v-for="(item, index) in list" :key="index">
            <div class="card-item" v-if="item.items.length">
              <div class="card">
                <img :src="item.items[0].image_icon" alt="" />
                <img class="levelIcon" :src="levelsChange(item.items[0].levels)" />
                <div class="icon">
                  <!-- :name="item.cheacked ? coll_choose : coll_no_choose" -->
                  <van-icon
                    :name="item.checked ? coll_choose : coll_no_choose"
                    class=""
                    @click="addCheck(index, 1)"
                  />
                </div>
              </div>
              <div class="t-c num">
                <!-- 编号：{{ item.items[0].serial_num }} -->
                <div class="selectBox">
                  <div style="width: 100%" @click.stop="showPickerHandle(item.items, index, 1)">
                    编号:
                    <span>{{ item.chooseNum }}</span>
                    <van-icon class="iconSel" name="play" />
                  </div>
                </div>
              </div>
            </div>
            <div class="nameBox" v-if="item.items.length">
              <div>《{{ item.display_name }}》</div>
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- step2 -->
      <div class="step2">
        <div class="stepTitle">
          <img src="../../imgs/learn/step2.png" alt="" />请选择叠加权益的藏品
          <van-icon @click="rightDesc = true" class="questionIcon" name="question-o" />
        </div>
        <div class="card-box">
          <div class="" v-for="(item, index) in step2List" :key="index">
            <div class="card-item" v-if="item.items.length">
              <img class="fastIcon" src="../../imgs/learn/fastIcon.png" alt="" srcset="" />
              <div class="card">
                <img :src="item.items[0].image_icon" alt="" />
                <img class="levelIcon" :src="levelsChange(item.items[0].levels)" />
                <div class="icon">
                  <!-- :name="item.cheacked ? coll_choose : coll_no_choose" -->
                  <van-icon
                    :name="item.checked ? coll_choose : coll_no_choose"
                    @click="addCheck(index, 2)"
                  />
                </div>
              </div>
              <div class="t-c num">
                <!-- 编号：{{ item.items[0].serial_num }} -->
                <div class="selectBox">
                  <div style="width: 100%" @click.stop="showPickerHandle(item.items, index, 2)">
                    编号:
                    <span>{{ item.chooseNum }}</span>
                    <van-icon class="iconSel" name="play" />
                  </div>
                </div>
              </div>
            </div>
            <div class="nameBox" v-if="item.items.length">
              <div>《{{ item.display_name }}》</div>
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="type2">
        <div class="bt-left">
          <div class="choose" @click="showpicker = true">
            <span class="words"> 修炼{{ days }}天</span>
            <div class="icon"><van-icon :name="icon_up_d" class="" /></div>
          </div>
          <div class="point" @click="knownBack = true">
            <div class="shortHour">修炼时长缩短{{ showHour }}个小时</div>
            <div>
              可获得<span>{{ showPoint }}</span
              >积分<van-icon name="question-o" />
            </div>
          </div>
        </div>
        <div
          class="btn"
          :style="{ opacity: user_collection_id && equity_id ? 1 : 0.7 }"
          @click="startLesrning"
        >
          开始修炼
        </div>
      </div>
    </div>

    <div class="" id="invite-award">
      <van-popup class="vantPop" v-model:show="knownBack">
        <div class="popup">
          <div class="content">
            <div class="title">闭关修炼说明</div>
            <div class="nei">
              <p>
                1、本次参与闭关修炼的藏品：《仙武帝尊》「东荒女帝」、「荒古大帝」「叶辰」、「姬凝霜」，《盖世帝尊》赤火灵鸟；
              </p>
              <p>2、闭关修炼期间藏品不可转赠、不可合成，但不影响藏品本身的权益；</p>
              <p>3、闭关修炼期间可随时结束修炼，但当次修炼不会获得积分；</p>
              <p>4、闭关修炼得积分规则</p>
            </div>
          </div>
          <div class="btn">
            <div @click.stop="knownBack = false">知道了</div>
          </div>
        </div>
      </van-popup>
    </div>

    <div class="" id="invite-award">
      <van-popup class="vantPop" v-model:show="rightDesc">
        <div class="popup">
          <div class="content">
            <div class="title">权益说明</div>
            <div class="nei">
              <p>1、闭关修炼7天，选择「乌骓」一起修炼，对应藏品修炼时间减少6小时。</p>
              <p style="border-bottom: 1px solid #494359">
                2、闭关修炼30天，选择「乌骓」一起修炼，对应藏品修炼时间减少24小时。
              </p>
              <p style="text-align: center">【注意事项】</p>
              <p>
                1、闭关修炼未结束，中途取消将无法获得积分，「乌骓」权益刷新，可以用于下一个藏品一起闭关修炼；
              </p>
              <p>2、“转赠”中和“许愿”中的「乌骓」无法选择与修炼藏品一同修炼；</p>
              <p>3、藏友获得「乌骓」后，权益自动刷新，可以直接与藏品闭关修炼，减少对应的时间；</p>
            </div>
          </div>
          <div class="btn">
            <div @click.stop="rightDesc = false">知道了</div>
          </div>
        </div>
      </van-popup>
    </div>

    <van-action-sheet
      v-model:show="showpicker"
      :actions="columns"
      @select="pickerChange"
      cancel-text="取消"
      @cancel="showpicker = false"
    />

    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker
        class="picker"
        :columns="numArr"
        value-key="serial_num"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import { regularconfig, getcomwaitregularList, comcollectionregular } from '@/service/learn';
import { Toast } from 'vant';
import { nftUtils } from '@/utils';
import { useRouter } from 'vue-router';

export default {
  components: {},
  setup() {
    const router = useRouter();
    const state = reactive({
      isApp: nftUtils.isInApp(),
      list: [],
      step2List: [],
      columns: [],
      days: null,
      showPoint: 0,
      configList: [],
      checkedArr: [],
      knownBack: false,
      showpicker: false,
      numSelect: false,
      showPicker: false,
      pickerIndex: null,
      numArr: [],
      user_collection_id: null,
      equity_id: null,
      checkedObj: {},
      showHour: 0,
      equityObj: {},
      rightDesc: false
    });
    onMounted(() => {
      getList();
      getBasic();
    });

    const getList = async () => {
      const { data, server_time } = await getcomwaitregularList();
      state.list = data.step_items;
      state.step2List = data.ste2_items;

      state.list = state.list.map(item => {
        if (item.items.length) {
          item.chooseNum = item.items[0].serial_num;
          item.items = item.items.map(subitem => {
            return { ...subitem, checked: false };
          });
        }
        return { ...item, checked: false, numSelect: false };
      });

      state.step2List = state.step2List.map(item => {
        if (item.items.length) {
          item.chooseNum = item.items[0].serial_num;
          item.items = item.items.map(subitem => {
            return { ...subitem, checked: false };
          });
        }
        return { ...item, checked: false, numSelect: false };
      });

      console.log(state.step2List);
    };

    // 获取基础配置
    const getBasic = async () => {
      const { data } = await regularconfig();
      state.configList = data;
      // 获取可选择天数配置
      state.configList.map(item => {
        state.columns.push({ id: item.days, name: `修炼${item.days}天` });
      });

      let map = new Map();
      for (let item of state.columns) {
        if (!map.has(item.id)) {
          map.set(item.id, item);
        }
      }
      state.columns = [...map.values()];
      state.days = state.columns[0].id;
    };

    // 选择时间类型切换
    const pickerChange = ({ id, name }) => {
      state.days = id;
      computedPoint();
      computedHour();
      state.showpicker = false;
    };

    // 开始修炼
    const startLesrning = async () => {
      if (!state.user_collection_id && !state.equity_id) {
        return;
      }
      const { data, message, status } = await comcollectionregular({
        user_collection_id: state.user_collection_id,
        equity_id: state.equity_id,
        days: state.days
      });
      if (status == 0) {
        getList();
        Toast('藏品已开始修炼');
        router.push({ path: '/Learning/retreat', replace: true });
      } else {
        Toast(message);
      }
    };

    // 计算积分
    const computedPoint = () => {
      state.showPoint = 0;
      state.configList.forEach(element => {
        if (element.token_id == state.checkedObj.token_id && element.days == state.days) {
          state.showPoint += element.points;
        }
      });
    };

    const computedHour = () => {
      state.showHour = 0;
      state.configList.forEach(element => {
        if (
          element.token_id == state.equityObj.token_id &&
          element.days == state.days &&
          element.type == 2
        ) {
          state.showHour = element.hour;
        }
      });
    };

    const addCheck = (index, step) => {
      if (step == 1) {
        state.list.forEach(item => {
          item.checked = false;
        });
        state.list[index].checked = !state.list[index].checked;
      } else {
        state.step2List.forEach(item => {
          item.checked = false;
        });
        state.step2List[index].checked = !state.step2List[index].checked;
      }

      asetCard(step);
    };

    // 重置已选
    const asetCard = step => {
      // 重置选择的藏品
      // state.checkedArr = [];
      state.stopPoint = 0;
      if (step == 1) {
        state.list.forEach(v => {
          console.log(v);
          if (v.checked) {
            v.items.forEach(i => {
              if (i.serial_num == v.chooseNum) {
                // state.checkedArr.push(i);
                state.checkedObj = i;
                state.user_collection_id = i.user_collection_id;
                computedPoint();
              }
            });
          }
        });
      } else {
        state.step2List.forEach(v => {
          console.log(v);
          if (v.checked) {
            v.items.forEach(i => {
              if (i.serial_num == v.chooseNum) {
                state.equityObj = i;
                state.equity_id = i.user_collection_id;
                computedHour();
              }
            });
          }
        });
      }
    };

    // 选择编号
    const showPickerHandle = (arr, index, step) => {
      state.pickerIndex = index;
      state.numArr = arr.map(item => {
        return {
          serial_num: item.serial_num,
          user_collection_id: item.user_collection_id,
          step: step
        };
      });
      state.showPicker = true;
    };

    // 选择编号
    const onConfirm = val => {
      if (val.step == 1) {
        state.list[state.pickerIndex].chooseNum = val.serial_num;
      } else {
        state.step2List[state.pickerIndex].chooseNum = val.serial_num;
      }
      asetCard();

      state.showPicker = false;
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('@/imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('@/imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('@/imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('@/imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('@/imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('@/imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    return {
      ...toRefs(state),
      startLesrning,
      pickerChange,
      getBasic,
      levelsChange,
      showPickerHandle,
      onConfirm,
      addCheck,
      icon_up_d: require('@/imgs/icon/icon_up_down.png'),
      coll_no_choose: require('@/imgs/icon/coll_no_choose.png'),
      coll_choose: require('@/imgs/icon/coll_choose.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';
.appTop {
  padding-top: 70px !important;
}
.picker {
  --van-picker-background-color: #2f293f;
  --van-picker-confirm-action-color: white;
  --van-picker-cancel-action-color: white;
  --van-picker-option-text-color: #ffffff;
}
.main {
  padding: 16px 0 66px 16px;
  box-sizing: border-box;

  .stepTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 14px;

    img {
      width: 48px;
      height: 18px;
      margin-right: 5px;
    }

    .questionIcon {
      padding-left: 5px;
      color: rgb(142, 142, 142);
    }
  }

  .step2 {
    margin-top: 30px;
  }

  .title {
    font-size: 16px;
    //  margin-left: 8px;
    position: relative;
    margin: 11px 0;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    .icon {
      font-size: 24px;
      margin-right: 16px;
      display: flex;
      align-items: center;
    }
    .name {
      margin-left: 10px;
    }
    span {
      font-size: 12px;
      margin-left: 3px;
      font-weight: 400;
    }
    &::before {
      content: '';
      width: 4px;
      height: 15px;
      background: #58dedd;
      position: absolute;
      left: 0;
      top: 4px;
    }
  }
  .card-box {
    display: flex;
    overflow: auto;
    .card-item {
      padding: 8px 7px 5px;
      background: #2b243f;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #494359;
      margin-right: 12px;
      position: relative;

      .fastIcon {
        width: 58px;
        height: 22px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
      }

      .tips {
        font-size: 10px;
        background: #000000;
        padding: 1px 2px;
        border-radius: 4px;
        position: absolute;
        z-index: 9;
        top: 0;
        left: 0;
      }

      .card {
        font-size: 0px;
        position: relative;
        img {
          // min-width: 79px;
          width: 79px;
        }
        .levelIcon {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 5px;
          width: 43px !important;
          // height: auto;
        }
        .icon {
          position: absolute;
          font-size: 22px;
          top: 0;
          right: 0;
          z-index: 10;
        }
      }
      .num {
        font-size: 10px;
        color: rgba(255, 255, 255, 0.7);
        line-height: 16px;
        margin-top: 7px;

        .selectBox {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          height: 26px;
          line-height: 26px;
          background: #1c172a;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid rgba(255, 255, 255, 0.7);
          font-size: 10px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.7);
          white-space: nowrap;
          padding-left: 5px;

          span {
            // width: 100%;
            line-height: 26px;
            display: inline-block;
            vertical-align: bottom;
            // padding: 0 8px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
          }

          .iconSel {
            position: absolute;
            right: 6px;
            top: 50%;
            font-size: 13px;
            transform: translateY(-50%) rotate(90deg);
          }

          .selMenu {
            overflow-y: scroll;
            position: absolute;
            z-index: 16;
            top: calc(100% + 3px);
            left: 0;
            width: 158px;
            max-height: 163px;
            background: #1c172a;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #c1bfc5;

            > div {
              display: flex;
              align-items: center;
              line-height: 36px;
              padding: 0 8px;
              font-size: 12px;
              font-weight: 400;
              color: rgba(255, 255, 255, 0.6);
              border-bottom: 1px solid #4d426b;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            }

            .activeMenu {
              font-size: 14px;
              font-weight: 400;
              color: #3cdbe8;
            }

            .levelIcon {
              width: 29px;
            }
          }
        }
      }
    }

    .nameBox {
      padding-right: 12px;
      text-align: center;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.footer {
  position: fixed;
  bottom: 0;
  padding: 16px;
  background: #29233a;
  width: 100%;
  border-top: #494359 1px solid;
  box-sizing: border-box;
  z-index: 999;

  .type2 {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    .bt-left {
      font-size: 12px;
      display: flex;
      align-items: center;
      .choose {
        display: flex;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #9991af;
        align-items: center;
        margin-right: 7px;
        .words {
          padding: 0 4px;
        }
        .icon {
          background: #9991af;
          font-size: 16px;
          display: flex;
          align-items: center;
          padding: 3px;
        }
      }
      .point {
        .shortHour {
          color: #ffe39b;
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
        }
        span {
          color: $primary;
        }
      }
    }
  }

  .btn {
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px 4px 4px 4px;
    padding: 6px 7px;
    font-size: 16px;
    color: #1c172a;
    font-weight: 500;
  }
}

.popup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;

  .content {
    padding: 16px;
    line-height: 26px;
    .title {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }
    .nei {
      // max-height: 350px;
      // overflow: auto;
      img {
        width: 100%;
      }
    }
  }

  .btn {
    div {
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      border-top: 1px solid #494359;
    }
  }
}
</style>

<style lang="scss">
#invite-award {
  .vantPop {
    background: transparent !important;
  }
}
#sendGround {
  .van-picker__mask {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)),
      linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)) !important;
  }
  .van-picker-column__item--selected {
    --van-sidebar-selected-border-color: none;
  }
  .van-picker__frame {
    right: 0;
    left: 0;
  }
  .van-hairline-unset--top-bottom {
    border: none;
  }
  .van-hairline--top-bottom:after,
  .van-hairline-unset--top-bottom:after {
    border-color: #494359;
  }
  .van-picker__toolbar {
    background: rgb(33, 29, 42);
  }
}
</style>
